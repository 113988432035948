import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import MenuHamburger from "../components/MenuHamburger"
import Footer from "../components/Footer";
import { Helmet } from 'react-helmet'

export const Concerts = () => {

    const [showMenuHamburger, setShowMenuHamburger] = useState(true);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [language, setLanguage] = useState("en");
    
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1023) {
        setShowMenuHamburger(false);
      } else {
        setShowMenuHamburger(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
    <Helmet>
      <title>Tatu Kauppinen | Concerts</title>
      <meta name="description" content="Upcoming Concerts" />
    </Helmet>
    <div className="concert-container">
        {showMenuHamburger && <MenuHamburger isMenuOpen={isMenuOpen} isConcertsPage={true} language={language} />}
        {!showMenuHamburger && <Header isConcertsPage={true} language={language}/>}
       <div className="calendar-body">
            <div className="concert-upcoming-container">
                <h1 className="concert-heading">Upcoming</h1>
            </div>
            <div className="calendar-wrapper">
                <div className="concert-calendar-container">
                    <div className="calendar-image-container">
                        <img className="calendar-image" loading="lazy" src="https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/cello_on.jpg" />
                    </div>
                    <div className="concert-wrapper">
                        <h3 className="concert-name">Juuka Music Festival</h3>
                        <div className="calendar-date-container">
                            <time className="calendar-date">AUG 09, 2024 at 19:00</time>
                        </div>
                        <div className="concert-location">
                            <h3 className="concert-place2">Juuka Church</h3>
                            <h3 className="concert-place title-margin margin-small">|</h3>
                            <h3 className="concert-place">Juuka, FI</h3>
                        </div>
                        <hr></hr>
                        <a target="_blank" className="button-more" href="https://www.juuanmusiikkijuhlat.fi/">
                            MORE
                        </a>
                    </div>
                </div>
            </div>
            <div className="calendar-wrapper">
                <div className="concert-calendar-container">
                    <div className="calendar-image-container">
                        <img className="calendar-image external" loading="lazy" src="https://turunmusiikkijuhlat.fi/wp-content/uploads/2023/12/nephesh-1920-x-1080.png" />
                    </div>
                    <div className="concert-wrapper">
                        <h3 className="concert-name">Turku music festival</h3>
                        <div className="calendar-date-container">
                            <time className="calendar-date">AUG 11, 2024 at 17:00</time>
                        </div>
                        <div className="concert-location">
                            <h3 className="concert-place">Art House</h3>
                            <h3 className="concert-place title-margin margin-small">|</h3>
                            <h3 className="concert-place">Turku, FI</h3>
                        </div>
                        <hr></hr>
                        {/* <div className="players-container">
                            <p className="margin-bottom">Anne Luisa Kramb / Jona Schibilsky, Violin</p>
                            <p className="margin-bottom">Brian Isaacs / Eric Seohyun Moon, Viola</p>
                            <p>Alexander Wollheim / Tatu Kauppinen, Cello</p>
                        </div> */}
                        <div className="pieces-container">
                            <p className="margin-bottom">Seele Quartet</p>
                        </div>
                        <a target="_blank" className="button-more" href="https://turunmusiikkijuhlat.fi/en/tapahtuma/nephesh">MORE</a>
                    </div>
                </div>
            </div>
            <div className="calendar-wrapper last">
                <div className="concert-calendar-container">
                    <div className="calendar-image-container">
                        <img className="calendar-image" loading="lazy" src="https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/cello_on.jpg" />
                    </div>
                    <div className="concert-wrapper">
                        <h3 className="concert-name">Purbeck International Chamber Music Festival</h3>
                        <div className="calendar-date-container">
                            <time className="calendar-date">29.08.2024 - 01.09.2024</time>
                        </div>
                        <div className="concert-location">
                            {/* <h3 className="concert-place">Kuhmo Arts Center</h3>
                            <h3 className="concert-place title-margin margin-small">|</h3> */}
                            <h3 className="concert-place">Purbeck, ENG</h3>
                        </div>
                        <hr></hr>
                        {/* <div className="players-container">
                            <p>Jutta Seppinen, Conductor</p>
                            <p>Tatu Kauppinen, Cello</p>
                            <p>Turku Philarmonic Orchestra</p>
                        </div>
                        <div className="pieces-container">
                            <p className="margin-bottom margin-right">Aulis Sallinen : The Nocturnal Dances of Don Juanquixote, op.58</p>
                        </div> */}
                        <a target="_blank" className="button-more" href="https://picmf.org ">
                            MORE
                        </a>
                    </div>
                </div>
            </div>
       </div>
       <Footer />
    </div>
    </>
  );
};

export default Concerts;

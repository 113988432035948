import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import MenuHamburger from "../components/MenuHamburger"
import Footer from "../components/Footer"
import { Helmet } from 'react-helmet'

export const ContactFI = () => {

    const [loaded, setLoaded] = useState(false);
    const [showMenuHamburger, setShowMenuHamburger] = useState(true);
    const [showPortrait, setShowPortrait] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [language, setLanguage] = useState("fi");
    const [showMobile, setShowMobile] = useState(false);

    const imageClass = showPortrait ? 'background-image2' : 'background-image';

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth > 530) {
          setShowMobile(false);
        } else {
          setShowMobile(true);
        }
      };

      handleResize();
  
      window.addEventListener("resize", handleResize);
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1023) {
        setShowMenuHamburger(false);
      } else {
        setShowMenuHamburger(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setShowPortrait(false);
      } else {
        setShowPortrait(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

    useEffect(() => {
      setTimeout(() => {
        setLoaded(true);
      }, 2000);
    }, []);

    const imageStyle = {
        opacity: loaded ? 1 : 0,
        transform: loaded ? 'scale(1)' : 'scale(1.2)',
      };

  return (
    <>
      <Helmet>
        <title>Tatu Kauppinen | YHTEYSTIEDOT</title>
        <meta name="description" content="Ota yhteyttä - Pysy kuulolla" />
      </Helmet>
      <div className="contact-container">
        <div className="image-wrapper">
          {!showMobile ? (
            <img className={imageClass} loading="lazy" src="../photos/_AMA5460.jpg" />
              ) : (
            <img className="background-image3" loading="lazy" src="https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/_AMA5467.jpg" />
          )}
        </div>
        {showMenuHamburger && <MenuHamburger isMenuOpen={isMenuOpen} isContactPage={true} language={language} />}
        {!showMenuHamburger && <Header isContactPage={true} language={language} />}
        <div className="contact-body"> 
            <div className="contact-main">
                <div className="media-upcoming-container">
                    <h1 className="contact-heading">Ota yhteyttä</h1>
                    <a href="" className="contact-links-container" target="_blank">
                        <span className='navigation-icons hover-links links-contact'><svg width="1.6em" viewBox="0 0 36 36" fill="none" aria-labelledby="email icon" role="img" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M30 6H6C4.35 6 3 7.35 3 9V9.84074C3.00001 9.68494 3.0423 9.52734 3.13083 9.38655C3.37666 8.99562 3.88594 8.88244 4.26834 9.13375L18 18.1581L31.7317 9.13375C32.1141 8.88244 32.6233 8.99562 32.8692 9.38655C32.9573 9.52665 32.9996 9.6834 33 9.83845V9C33 7.35 31.65 6 30 6ZM33 9.84314C32.9992 10.1191 32.8659 10.3891 32.6219 10.5494L18.4451 19.8664C18.174 20.0445 17.826 20.0445 17.5549 19.8664L3.37812 10.5494C3.13347 10.3887 3.00002 10.1176 3 9.84086V27C3 28.65 4.35 30 6 30H30C31.65 30 33 28.65 33 27V9.84314Z" fill="currentColor"></path></svg></span>
                        <span className='margin hover-links links-contact display'>tatu.kauppinencello@gmail.com</span>
                    </a>
                </div>
                <div className="contact-main-container">
                    <h1 className="contact-heading">Pysy kuulolla</h1>
                    <div className="social-media-container">
                    <a href="https://www.instagram.com/tatu_kauppinen/" target="_blank" className="contact-links-container fit-content">
                        <span className='navigation-icons hover-links links-contact'><svg className="svg" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M24.7106 4.4209H10.7106C6.84458 4.4209 3.71057 7.5549 3.71057 11.4209V25.4209C3.71057 29.2869 6.84458 32.4209 10.7106 32.4209H24.7106C28.5766 32.4209 31.7106 29.2869 31.7106 25.4209V11.4209C31.7106 7.5549 28.5766 4.4209 24.7106 4.4209ZM28 9C28 9.55228 27.5523 10 27 10C26.4477 10 26 9.55228 26 9C26 8.44772 26.4477 8 27 8C27.5523 8 28 8.44772 28 9ZM16.0518 13.7604C16.9269 13.3063 17.9229 13.1397 18.8981 13.2843C19.8928 13.4319 20.8138 13.8954 21.5249 14.6065C22.236 15.3176 22.6995 16.2385 22.847 17.2333C22.9916 18.2085 22.825 19.2044 22.371 20.0795C21.9169 20.9546 21.1985 21.6642 20.3179 22.1075C19.4372 22.5507 18.4393 22.705 17.4659 22.5484C16.4926 22.3918 15.5934 21.9322 14.8963 21.2351C14.1992 20.538 13.7396 19.6388 13.583 18.6654C13.4263 17.6921 13.5806 16.6941 14.0239 15.8135C14.4671 14.9329 15.1767 14.2144 16.0518 13.7604ZM19.1366 11.6762C17.8231 11.4814 16.4817 11.7058 15.3031 12.3173C14.1244 12.9289 13.1687 13.8965 12.5717 15.0826C11.9747 16.2686 11.7669 17.6127 11.9779 18.9237C12.1888 20.2347 12.8078 21.4457 13.7467 22.3846C14.6856 23.3236 15.8967 23.9425 17.2076 24.1535C18.5186 24.3644 19.8627 24.1566 21.0488 23.5596C22.2348 22.9627 23.2025 22.0069 23.814 20.8283C24.4256 19.6497 24.6499 18.3082 24.4551 16.9948C24.2565 15.655 23.6322 14.4146 22.6744 13.4569C21.7167 12.4992 20.4763 11.8749 19.1366 11.6762Z" fill="currentColor"></path></svg></span>
                        <span className='margin hover-links links-contact display2'>@tatu_kauppinen</span>
                    </a>
                    <a href="https://www.facebook.com/tatu.kauppinen.3" target="_blank" className="contact-links-container fit-content">
                        <span className='navigation-icons hover-links links-contact'><svg className="svg" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M26.7106 4.46777H22.3469C20.4181 4.46777 18.5682 5.20527 17.2043 6.51803C15.8404 7.83078 15.0742 9.61126 15.0742 11.4678V15.6678H10.7106V21.2678H15.0742V32.4678H20.8924V21.2678H25.256L26.7106 15.6678H20.8924V11.4678C20.8924 11.0965 21.0456 10.7404 21.3184 10.4778C21.5912 10.2153 21.9612 10.0678 22.3469 10.0678H26.7106V4.46777Z" fill="currentColor"></path></svg></span>
                        <span className='margin hover-links links-contact display2'>Tatu Kauppinen</span>
                    </a>
                    <a href="" className="contact-links-container fit-content" target="_blank">
                        <span className='navigation-icons hover-links links-contact' ><svg className="svg" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M34.0203 10.1068C33.8339 9.41212 33.4662 8.77811 32.9542 8.26823C32.4421 7.75835 31.8036 7.3905 31.1026 7.20152C28.5299 6.51465 18.2106 6.51465 18.2106 6.51465C18.2106 6.51465 7.8912 6.51465 5.3172 7.20152C4.61651 7.39083 3.97835 7.75882 3.46655 8.26866C2.95476 8.77851 2.58726 9.41235 2.40082 10.1068C1.71057 12.6693 1.71057 18.0146 1.71057 18.0146C1.71057 18.0146 1.71057 23.36 2.40082 25.9225C2.58725 26.6172 2.95491 27.2512 3.46698 27.7611C3.97906 28.2709 4.61757 28.6388 5.31857 28.8278C7.8912 29.5147 18.2106 29.5146 18.2106 29.5146C18.2106 29.5146 28.5299 29.5147 31.1039 28.8278C31.805 28.6389 32.4436 28.2711 32.9557 27.7612C33.4678 27.2513 33.8354 26.6172 34.0217 25.9225C34.7106 23.36 34.7106 18.0146 34.7106 18.0146C34.7106 18.0146 34.7106 12.6693 34.0203 10.1068ZM14.8349 22.8676V13.1617L23.4603 18.0146L14.8349 22.8676Z" fill="currentColor"></path></svg></span>
                        <span className='hover-links links-contact margin display2'>Tatu Kauppinen</span>
                    </a>
                    </div>    
                </div>
            </div>
        </div>
        <Footer/>
    </div>
    </>
  );
};

export default ContactFI;
